import React from "react";
import axios from "axios";
import Faq from "react-faq-component";
import { MDBContainer, MDBSelect, MDBCol, MDBRow, MDBTypography   } from "mdb-react-ui-kit";
import Select from 'react-select';
const baseURL = "https://apis.theheavenlyjerusalem.org/FAQs";
export default function App() {
  const [faqs, setFaqs] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState('English');
  const data = {
    title: "FAQ – Frequently asked questions and answers",
    rows: faqs,
};
  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
  React.useEffect(() => {
    console.log(selectedOption)
    axios.get(`${baseURL}?language=English`).then((response) => {
      setFaqs(response.data.data);
    });
    axios.get(`https://apis.theheavenlyjerusalem.org/Languages`).then(resp => {
    setLanguages(resp.data.languages);
  });
  }, []);
 
  const handleChange =(e)=>{
    console.log(e.value)
    setSelectedOption(e.value)
    axios.get(`${baseURL}?language=${e.value}`).then((response) => {
      setFaqs(response.data.data);
    });
  }
  return (
    <>
 <MDBContainer breakpoint="md" className="mt-5">
<MDBRow >
  <MDBCol style={{float:'right'}} size={12}>
     <Select
        placeholder={'English'}
        defaultValue={selectedOption}

        onChange={(e)=>handleChange(e)}
        options={languages}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
          
            margin:"0px",
            width:200,
            float:'right'
          }),
       }}
      />
     </MDBCol>
     </MDBRow>
     </MDBContainer>
    <MDBContainer breakpoint="md" className="mt-1">
     
     <MDBRow>
     <MDBCol >
      {
        faqs.length>0?(
      <Faq
          data={data}
          styles={styles}
          config={config} />
        ):
        <MDBTypography  className="text-center mt-5" variant='h4'>No FAQs are available in selected language</MDBTypography>
        }

</MDBCol>
</MDBRow>
</MDBContainer>
</>
      );
    }

   